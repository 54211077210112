<script>
import { onMounted, onUnmounted, watch, ref, reactive ,computed } from 'vue'
import useScrollData from '@/reactive/useScrollData'
import {useRoute} from 'vue-router'

export default {
  setup() {
    const route = useRoute()

    let userId = route.query.userId
    let name = route.query.name

    let { tabType } = useScrollData()
    tabType.value = 'SHOP'

    onMounted(() => {
      if(route.path === '/shop-order/shop'){
        tabType.value = 'SHOP'
      }else{
        tabType.value = 'ORDER'
      }
    })
    return {
      userId,
      name,
      tabType
    }
  }

}
</script>
<template>
  <div style="padding-bottom:10px">
    <div style=" position:relative; height: 45px">
      <div class="tab">
        <span @click="tabType = 'SHOP';$router.replace({path:'/shop-order/shop',query:{userId:userId,name:name}})" :class="{active: tabType === 'SHOP'}" >门店</span>
        <span @click="tabType = 'ORDER';$router.replace({path:'/shop-order/order',query:{userId:userId,name:name}})" :class="{active: tabType === 'ORDER'}">订单</span>
      </div>
    </div>
    <router-view></router-view>
  </div>

</template>

<style lang="less" scoped>
  .tab { padding:15px 0;  background: #fff;position: fixed; top: 0; display:table; width:100% }
  .tab span { display:table-cell; font-size: .28rem; text-align: center}
  .tab span.active { color: #00ABEB ;font-weight: bold; }
  .r-box{
    padding: 0;
    background: none;
  }
</style>